<template>
  <div class="upload-document fill-height">

    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">

        <nav-bar-page/>

        <v-container>
          <v-row justify="center" class="mt-0">
            <v-col cols="12" class="mt-0 py-0">
              <div class="my-0 pt-0 primary--text">
                <h1 class="text-center today-regular text-title">REGISTRAR DOCUMENTO</h1>
                <p class="primary--text text-center mb-0 mt-2 today-regular">Escanea la parte delantera y posterior de tu cédula</p>
              </div>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-8">
            <v-col cols="12" md="4" lg="4" class="mx-4">
              <v-card color="grey lighten-4" elevation="0" style="border-radius: 12px;" class="pb-6">
                <v-card-title class="text-center justify-center primary--text today-regular line-height-1">Foto frontal del
                  <br>documento</v-card-title>

                <v-row justify="center">
                  <v-col cols="10">
                    <v-img v-if="!frontPreviewImage" src="https://app-biocredit.s3.amazonaws.com/vardi/frontUser.png" height="160" class="mt-2" contain></v-img>
                    <v-img v-if="frontPreviewImage" :src="frontPreviewImage" height="160" class="mt-2" contain></v-img>
                    <v-btn
                        v-if="!frontPreviewImage"
                        color="white"
                        class="text-capitalize mt-2 primary--text today-medium"
                        block
                        :loading="isSelectingFront"
                        @click="onButtonClickFront"
                    >
                      Adjuntar imagen
                    </v-btn>

                    <v-btn
                        v-else
                        color="white"
                        class="text-capitalize mt-2 primary--text today-medium"
                        block
                        :loading="isDeletingFront"
                        @click="onButtonDeleteFront"
                    >
                      Eliminar imagen
                    </v-btn>

                    <input
                        ref="uploaderFront"
                        class="d-none"
                        type="file"
                        accept="image/*"
                        @change="onFileChangedFront"
                    >

                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" lg="4" class="mx-4">
              <v-card color="grey lighten-4" elevation="0" style="border-radius: 12px;" class="pb-6">
                <v-card-title class="text-center justify-center primary--text today-regular line-height-1">Foto reverso del
                  <br>documento</v-card-title>

                <v-row justify="center">
                  <v-col cols="10">
                    <v-img v-if="!backPreviewImage" src="https://app-biocredit.s3.amazonaws.com/vardi/backUser.png" height="160" class="mt-2" contain></v-img>
                    <v-img v-if="backPreviewImage" :src="backPreviewImage" height="160" class="mt-2" contain></v-img>
                    <v-btn
                        v-if="!backPreviewImage"
                        color="white"
                        class="text-capitalize mt-2 primary--text today-medium"
                        block
                        :loading="isSelectingBack"
                        @click="onButtonClickBack"
                    >
                      Adjuntar imagen
                    </v-btn>
                    <v-btn
                        v-else
                        color="white"
                        class="text-capitalize mt-2 primary--text today-medium"
                        block
                        :loading="isDeletingBack"
                        @click="onButtonDeleteBack"
                    >
                      Eliminar imagen
                    </v-btn>
                    <input
                        ref="uploaderBack"
                        class="d-none"
                        type="file"
                        accept="image/*"
                        @change="onFileChangedBack"
                    >
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-layout justify-center pt-5>
            <v-card-actions>
              <v-btn
                  :loading="loading"
                  :disabled="loading"
                  @click="goPrepareSelfie"
                  color="primary"
                  class="px-12 text-capitalize vardi-button"
                  elevation="0"
                  large>
                Continuar
              </v-btn>
            </v-card-actions>
          </v-layout>

        </v-container>

      </v-col>
    </v-row>

    <v-dialog
        v-model="dialogManualData"
        max-width="400"
        overlay-color="primary"
    >
      <v-card>
        <v-card-title class="justify-center text-uppercase primary--text font-weight-bold today-medium">
          Ingresar información
        </v-card-title>

        <h4 class="my-4 text-justify primary--text today-light line-height-1 px-14">Tu documento no pudo ser procesado. Ingresa la información tal y como se encuentra en la cédula.</h4>

        <div class="px-14 pb-6 mt-4">
          <v-form
              ref="form"
              lazy-validation
              @submit.prevent="goManualValidateDocuments">

            <v-text-field
                v-model="firstName"
                :rules="[v => !!v || 'Ingresa la información']"
                type="text"
                label="Primer nombre"
                required
                outlined
                dense
                color="primary"
                class="input-field"
            ></v-text-field>

            <v-text-field
                v-model="secondName"
                type="text"
                label="Segundo nombre"
                required
                outlined
                dense
                color="primary"
                class="input-field"
            ></v-text-field>

            <v-text-field
                v-model="firstLastName"
                :rules="[v => !!v || 'Ingresa la información']"
                type="text"
                label="Primer apellido"
                required
                outlined
                dense
                color="primary"
                class="input-field"
            ></v-text-field>

            <v-text-field
                v-model="secondLastName"
                type="text"
                label="Segundo apellido"
                required
                outlined
                dense
                color="primary"
                class="input-field"
            ></v-text-field>

            <v-text-field
                v-model="birthDate"
                :rules="[v => !!v || 'Ingresa la información']"
                type="date"
                label="Fecha de nacimiento"
                required
                outlined
                dense
                color="primary"
                class="input-field"
            ></v-text-field>

            <v-select
                v-model="gender"
                :items="genders"
                item-text='value'
                item-value='key'
                :rules="[v => !!v || 'Selecciona el campo']"
                label="Genero"
                required
                outlined
                dense
                color="primary"
                class="select-field"
            ></v-select>

            <v-layout justify-center pt-4>
              <v-card-actions>
                <v-btn
                    :loading="loading"
                    :disabled="loading"
                    color="primary"
                    type="submit"
                    class="px-12 text-capitalize vardi-button">
                  Continuar
                </v-btn>
              </v-card-actions>
            </v-layout>

          </v-form>
        </div>

      </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        bottom
        right>
      {{ snackbarMessage }}
    </v-snackbar>

  </div>

</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import NavBarPage from "@/components/Layouts/NavBarPage";
import {api} from "@/helpers/axios";
import {constants} from "@/helpers/constants";
import {locals} from "@/helpers/locals";

export default {
  name: 'UploadDocument',
  components: {LeftSideImage, NavBarPage},
  data() {
    return {
      defaultButtonText: 'Adjuntar imagen',
      selectedFileFront: null,
      selectedFileBack: null,
      isSelectingFront: false,
      isSelectingBack: false,
      frontPreviewImage: null,
      backPreviewImage: null,
      isDeletingFront: false,
      isDeletingBack: false,
      loading: false,
      snackbar: false,
      snackbarMessage: '',
      timeout: 3000,
      settings: [],
      user: null,
      validateDocumentId: false,
      dialogManualData: false,
      firstName: '',
      secondName: '',
      firstLastName: '',
      secondLastName: '',
      birthDate: '',
      rh: 'RH',
      gender: '',
      genders: [
        {
          "key": "M",
          "value": "Masculino"
        },
        {
          "key": "F",
          "value": "Femenino"
        }
      ],
    }
  },
  created() {
    this.settings = JSON.parse(localStorage.getItem('settings'));
    this.user = locals.getLocal('user');
    this.frontPreviewImage = this.user.front_preview_image;
    this.backPreviewImage = this.user.back_preview_image;
    this.validateDocumentId = !!this.user.validate_document_id;
    console.log('UploadDocument', this.settings);
  },
  methods: {
    goManualValidateDocuments() {

      var formData = new FormData();
      formData.append('document_id', this.user.document_id);
      formData.append('document_type', this.user.document_type);
      formData.append('first_name', this.firstName);
      formData.append('second_name', this.secondName);
      formData.append('last_name', this.firstLastName);
      formData.append('second_last_name', this.secondLastName);
      formData.append('birth_date', this.birthDate);
      formData.append('rh', this.rh);
      formData.append('gender', this.gender);
      formData.append('validate_document_id', this.validate_document_id);

      api.postFile(constants.endPoints.manualValidateDocuments, formData, true).then(function (response) {
        console.log('manualValidateDocuments: ', response);

        this.loading = false;

        if(response.data.errors != null) {
          let errors = response.data.errors;
          console.log('errors: ', errors);
          let message = '';
          for (var [key, value] of Object.entries(errors)) {
            console.log(key + ' : ' + value);
            message = message + value + '\n';
          }
          this.snackbarMessage = message;
          this.snackbar = true;

        } else {
          console.log('Sin errores', response.data.data.user_document_verify);

          this.user.validate_document_id = response.data.data.user_document_verify;
          locals.setLocal('user', JSON.stringify(this.user));
          this.$router.push('/preparate-para-selfie');
        }

      }.bind(this)).catch(function (error) {
        console.log('errors: ', error);
        let errors = error.response.data.errors;
        let message = '';
        for (var [key, value] of Object.entries(errors)) {
          console.log(key + ' : ' + value);
          message = message + value + '\n';
        }
        this.snackbarMessage = message;
        this.snackbar = true;
        this.loading = false;

      }.bind(this))

    },
    goPrepareSelfie() {
      //if(this.validateDocumentId) {
      //  this.$router.push('/preparate-para-selfie');
      //}
      this.loading = true;
      console.log(this.selectedFileFront, this.selectedFileBack);
      if(this.frontPreviewImage == null || this.backPreviewImage == null) {
        this.snackbarMessage = 'Debes seleccionar la imagen frontal y reverso del documento';
        this.snackbar = true;
        this.loading = false;
      } else {
        this.user = locals.getLocal('user');

        var formData = new FormData();
        formData.append('document_id', this.user.document_id);
        formData.append('document_type', this.user.document_type);
        formData.append('front_image', this.selectedFileFront);
        formData.append('back_image', this.selectedFileBack);

        api.postFile(constants.endPoints.validateDocuments, formData, true).then(function (response) {
          console.log('validateDocuments: ', response);

          this.loading = false;

          if(response.data.errors != null) {
            let errors = response.data.errors;
            let manualRegister = response.data.data.manual_register;
            console.log('errors: ', errors);
            let message = '';
            for (var [key, value] of Object.entries(errors)) {
              console.log(key + ' : ' + value);
              message = message + value + '\n';
            }
            this.snackbarMessage = message;
            this.snackbar = true;

            if(manualRegister) {
              this.dialogManualData = true;
              this.validate_document_id = response.data.data.user_document_verify;
              this.user.validate_document_id = response.data.data.user_document_verify;
              locals.setLocal('user', JSON.stringify(this.user));
            }
          } else {
            console.log('Sin errores', response.data.data.user_document_verify);

            this.user.validate_document_id = response.data.data.user_document_verify;
            this.user.front_preview_image = response.data.data.front_document_image;
            this.user.back_preview_image = response.data.data.back_document_image;
            locals.setLocal('user', JSON.stringify(this.user));
            this.$router.push('/preparate-para-selfie');
          }

        }.bind(this)).catch(function (error) {
          console.log('errors: ', error);
          let errors = error.response.data.errors;
          let message = '';
          for (var [key, value] of Object.entries(errors)) {
            console.log(key + ' : ' + value);
            message = message + value + '\n';
          }
          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;

        }.bind(this))

        /*setTimeout(function () {
          this.loading = false;
          this.$router.push('/preparate-para-selfie');
        }.bind(this), 1000)*/
      }

    },

    onButtonClickFront() {
      this.isSelectingFront = true
      window.addEventListener('focus', () => {
        this.isSelectingFront = false
      }, { once: true })

      this.$refs.uploaderFront.click()
    },
    onFileChangedFront(e) {
      this.selectedFileFront = e.target.files[0]

      if (this.selectedFileFront) {
        let reader = new FileReader
        reader.onload = e => {
          this.frontPreviewImage = e.target.result
        }
        reader.readAsDataURL(this.selectedFileFront)
        this.$emit('input', this.selectedFileFront)
      }

      console.log('Front', this.selectedFileFront);
      // do something
    },
    onButtonClickBack() {
      this.isSelectingBack = true
      window.addEventListener('focus', () => {
        this.isSelectingBack = false
      }, { once: true })

      this.$refs.uploaderBack.click()
    },
    onFileChangedBack(e) {
      this.selectedFileBack = e.target.files[0]

      if (this.selectedFileBack) {
        let reader = new FileReader
        reader.onload = e => {
          this.backPreviewImage = e.target.result
        }
        reader.readAsDataURL(this.selectedFileBack)
        this.$emit('input', this.selectedFileBack)
      }

      console.log('Back ', this.selectedFileBack);
      // do something
    },
    onButtonDeleteFront() {
      this.validateDocumentId = false; // Se valida si ya existe, lo que quiere decir que ya se subieron imagenes
      this.isDeletingFront = true;
      setTimeout(function () {
        this.isDeletingFront = false;
        this.frontPreviewImage = null;
        this.selectedFileFront = null;
      }.bind(this), 1000)
    },
    onButtonDeleteBack() {
      this.validateDocumentId = false; // Se valida si ya existe, lo que quiere decir que ya se subieron imagenes
      this.isDeletingBack = true;
      setTimeout(function () {
        this.isDeletingBack = false;
        this.backPreviewImage = null;
        this.selectedFileBack = null;
      }.bind(this), 1000)
    }
  },
  computed: {
    buttonText() {
      return this.selectedFileBack ? this.selectedFileBack.name : this.defaultButtonText
    }
  },
}
</script>
<style scoped>

.v-toolbar__content {
  justify-content: left !important;
}

.v-list-item--dense {
  min-height: 30px;
}

.v-btn {
  letter-spacing: 0 !important;
  border-radius: 6px;
}

.v-card__title {
  word-break: inherit;
}

</style>